@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

a:hover {
    color: #333 !important
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiStepIcon-root {
  border: 3px solid #808285;
  border-radius: 50px;
  color: transparent;
}

.MuiStepIcon-root {
  color: transparent !important;
}

.MuiStepIcon-text {
  fill: #808285 !important;
}

.MuiStepIcon-active {
  border-color: #53CD94;
  color: transparent !important;
}

.MuiStepIcon-completed {
  border-color: #53CD94;
  color: #53CD94 !important;
  padding: 1px;
}

.MuiStepIcon-completed > .MuiStepIcon-text,
.MuiStepIcon-active > .MuiStepIcon-text {
  fill: #53CD94 !important;
}

div[class*=" Carousel-prev-"] {
  right: 50px !important;
  left: auto !important;
}

.react-multi-carousel-list {
  width: 100% !important;
  min-height: 100px;
  padding-bottom: 30px
}

.dashboard-offer-dot-style > li > button {
  border-color: #C4C4C4;
  background: #C4C4C4;
}

.dashboard-offer-dot-style > li.react-multi-carousel-dot--active > button {
  background: #53CD94 !important;
  border-color: #53CD94;
}

.react-multiple-carousel__arrow--left {
  left: 0% !important;
  top: 125px !important;
}

.react-multiple-carousel__arrow--right {
  right: 0% !important;
  top: 125px !important;
}


.MuiInput-underline.Mui-disabled:before {
  border-bottom: none !important;
}

#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left,
#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  background: #FFF !important;
  min-width: 24px !important;
  min-height: 24px !important;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(224, 224, 224);
  color: rgb(83, 205, 148);
  border-radius: 4px;
  top: 100px !important;
}

#by-seller-slider .react-multiple-carousel__arrow::before {
  color: rgb(83, 205, 148) !important;
  font-size: 14px !important;
}

#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 5px !important;
}

#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 5px !important;
}

.kr-embedded {
  width: 450px !important;
  padding: 20px !important;
}
.kr-embedded .kr-payment-button {
  background-color: #53cd94 !important;
  border-radius: 8px;
  width: 400px !important;
  box-shadow: 3px 3px 3px #808285 !important;
  margin-bottom: 20px !important;
}
.kr-pan, .kr-expiry, .kr-security-code, .kr-installment-number, .kr-first-installment-delay  {
  border-radius: 5px !important;
  border: 1px solid #808285 !important;
  width: 400px !important;
}

/* .kr-expiry{
  border-radius: 5px !important;
  border: 1px solid #808285 !important;
  width: 400px !important;
}
.kr-security-code{
  border-radius: 5px !important;
  border: 1px solid #808285 !important;
  width: 400px !important;
} */

.kr-embedded .kr-modal-wrapper .kr-modal .kr-modal-footer button span, .kr-embedded .kr-payment-button span {
  color: white;
  font-weight: 800;
  font-size: 20px;
}
/*
.kr-installment-number{
  display: none !important;
  visibility: hidden !important;
  width: 400px !important;
  border-radius: 5px !important;
}

.kr-first-installment-delay{
  display: none !important;
  visibility: hidden !important;
  width: 400px !important;
  border-radius: 5px !important;
} */
/* .MuiBox-root-3{
  padding:0 !important;
  box-shadow: none !important;

} */
.MuiPaper-elevation1{
  box-shadow: none !important;
}
@media only screen and (max-width: 600px) {
  .kr-embedded {
    width: 100% !important;

  }
  .react-multiple-carousel__arrow {
      min-width: 30px;
      min-height: 30px;
  }
  .react-multiple-carousel__arrow--left {
    left: 0% !important;
    top: 110px !important;
  }

  .react-multiple-carousel__arrow--right {
    right: 0% !important;
    top: 110px !important;
  }
  .kr-pan, .kr-expiry, .kr-security-code, .kr-installment-number, .kr-first-installment-delay{
    /* border-radius: 5px !important;
    border: 1px solid #808285 !important; */
    width: 100% !important;
  }
  .kr-embedded .kr-payment-button {
    /* background-color: #53cd94 !important;
    border-radius: 8px; */
    width: 100% !important;
    /* box-shadow: 3px 3px 3px #808285 !important;
    margin-bottom: 20px !important; */
  }
}
