@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: RubikLight;
  src: url(/static/media/Rubik-Light.2e276ea6.ttf);
}
@font-face {
  font-family: RubikMedium;
  src: url(/static/media/Rubik-Medium.c9459b0b.ttf);
}
@font-face {
  font-family: RubikRegular;
  src: url(/static/media/Rubik-Regular.0f56ab0b.ttf);
}
@font-face {
  font-family: RubikBold;
  src: url(/static/media/Rubik-Bold.d62658f2.ttf);
}
@font-face {
  font-family: FontAwesomeFree;
  src: url(/static/media/zxepn-02l2m.3c2f4ecf.ttf);
}
button:focus,
input:focus,
textarea:focus,
.btn:focus {
  box-shadow: none;
  outline: none;
  outline: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
body {
  position: relative;
}
nav {
  background-color: transparent;
  padding-top: 1%;
  padding-bottom: 1%;
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.list-absolute-nav {
  width: 100%;
  height: 0vh;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 9999;
  padding-top: 0%;
  transition: height 0.5s;
  overflow: hidden;
  position: fixed;
}
.list-absolute-nav-open {
  height: 100vh;
}
.btnt-close {
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: #66b78e;
  font-size: 2.1875rem;
  float: right;
}
.li-nav-top {
  margin-top: 20%;
}
.nav-color {
  color: #026e51;
  text-decoration: none;
  font-family: RubikLight;
  font-size: 18px;
}
.btn-nav {
  background-color: #f0994a !important;
  color: #ffffff !important;
  /* font-family: RubikMedium; */
  height: 3.125rem;
  box-shadow: 0px 10px 15px #00000029;
  border-radius: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
}
.nav-color:active,
.nav-color:hover,
.nav-color:focus {
  /* background-color: #ffffff !important;
        border-color:  #F0994A !important; */
  color: #000000 !important;
  border-color: #f0994a !important;
}
.btn-nav:hover,
.btn-nav:active,
.btn-nav:focus,
.btn-nav:active {
  background-color: #ffffff !important;
  border-color: #f0994a !important;
  color: #f0994a !important;
}
.div-conten-video {
  /* position: relative; */
  padding-top:1.5rem;
  height: 90vh;
  /* display: flex; */
}
.div-conten-videovender {
  position: relative;
  height: 90vh;
}
video.divdeo-fndo {
  width: 40% !important;
  margin-left: 60%;
  margin-top: 10%;
  height: 70% !important;
  display: none;
}
.video-desktop {
  display: none;
}
.video-mobile {
  display: block;
  /* width:80%;
  margin:1rem auto */
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.div-titulos {
  position: absolute;
  width: 100%;
  background-color: #ffffff00;
  bottom: 10%;
  height: 100%;
  left: 0;
  z-index: 1;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-end;
  max-width: 1000px;
  padding-bottom: 15%;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.div-paadding {
  padding-bottom: 5% !important;
}
.div-venta {
  padding-left: 10%;
  padding-right: 10%;
}
.titulo {
  color: #026e51;
  font-family: RubikMedium;
  font-size: 2rem;
  width: 100%;
  text-align: center;
}
.typed-text {
  min-height: 140px;
  text-align: center;
}
.div-btn-register {
  display:flex;
  justify-content: center;
  margin:1rem 0;
}
.btn-registrar {
  color: #026e51 !important;
  font-family: RubikMedium;
  font-size: 1.25rem !important;
  background-color: #fdc727 !important;
  border-radius: 36px !important;
  box-shadow: 0px 10px 15px #00000029;
  height: 3.125rem;
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
  transition: all 0.3s ease-in-out !important;
  text-align: center;
}
.btn-registrar:hover {
  color: #fdc727 !important;
  background-color: #026e51 !important;
}
.div-content-cuadrado {
  height: 40rem;
}
.div-content-cuadrado::before {
  content: "";
  background-color: #fbc527;
  border-radius: 0px 140px 70px 131px;
  width: 70%;
  height: 30.625rem;
  position: absolute;
  top: 0;
  right: 28%;
  z-index: 0;
  box-shadow: 0px 10px 16px #00000029;
}
.div-verde {
  background-color: #66b78e;
  border-radius: 0px 140px 70px 131px;
  width: 100%;
  height: 100%;
  padding: 3.0625rem 1rem;
  position: absolute;
  left: 0%;
  top: -1.875rem;
  z-index: 1;
}
.btn-border-blanco {
  background-color: transparent !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  font-family: RubikRegular;
  font-size: 1.25rem !important;
  border-radius: 36px !important;
  padding-left: 1.75rem !important;
  padding-right: 1.75rem;
  width: 100%;
  margin-left: 0%;
  height: 3.125rem !important;
  transition: all 0.3s ease-in-out;
}
.btn-border-blanco:hover {
  color: #66b78e !important;
  background-color: #ffffff !important;
}
.subtitulo-blanco {
  color: #ffffff;
  font-family: RubikMedium;
  font-size: 1.875rem;
}
.p-descripcion-blanco {
  color: #ffffff;
  font-family: RubikLight;
  font-size: 1.125rem;
}
.secc-top-uno {
  margin: 2rem 0;
}
.p-verde-size-18 {
  font-family: RubikLight;
  font-size: 1.125rem;
  color: #026e51;
}
.span-medium {
  font-family: RubikMedium !important;
}
.div-hoja {
  background-color: #66b78e;
  border-radius: 0px 140px 70px 131px;
  height: 8.5625rem;
  width: 8.25rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-shadow: 0px 10px 16px #00000029;
}
.div-conten-hojas::before {
  content: "";
  background-color: #fdc727;
  border-radius: 0px 140px 70px 131px;
  height: 7.8125rem;
  width: 7.5625rem;
  position: absolute;
  left: 20%;
  bottom: 9%;
  z-index: -1;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  box-shadow: 0px 10px 16px #00000029;
}
.img-icon-hoja {
  width: 60%;
}
.titulos-size-18-verde {
  color: #026e51;
  font-size: 1.125rem;
  font-family: RubikBold;
}
.p-descripcion-verde-peque {
  color: #026e51;
  font-size: 0.875rem;
  font-family: RubikLight;
}
.div-radius-amarrilo {
  background-color: #fdc727;
  border-radius: 0px 140px 0px 0px;
  width: 100%;
  margin-left: 0%;
  padding: 3.0625rem 1rem;
  box-shadow: 0px 10px 16px #00000029;
}
.border-negro-color {
  border-color: #414141 !important;
  color: #414141 !important;
  width: 100% !important;
  margin-left: 0% !important;
  transition: all 0.3s ease-in-out;
}
.border-negro-color:hover {
  color: #ffffff !important;
  background-color: #414141 !important;
}
.titulo-plomo {
  color: #414141;
  font-size: 2rem;
  font-family: RubikMedium;
}
.li-plomo {
  color: #414141;
  font-size: 1.125rem;
  font-family: RubikLight;
}
.img-absolute-left {
  position: absolute;
  left: 2%;
  top: 0;
  width: 40%;
  z-index: -1;
  display: none;
}
.secc-padding-top {
  padding-top: 0%;
}
.btn-regris-blanco {
  background-color: transparent !important;
  border-radius: 36px !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  font-family: RubikRegular;
  transition: all 0.3s ease-in-out;
}
.btn-regris-blanco:hover {
  color: #66b78e !important;
  background-color: #ffffff !important;
}
.div-card {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #dbdbdb;
  box-shadow: -7px 15px 15px #00000029;
}
.card-img {
  width: 100%;
  height: 13.5625rem;
  border-radius: 20px 20px 0px 0px;
}
.card-img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}
.div-mitad {
  width: 50%;
}
.card-content {
  padding: 1.5625rem 0.875rem;
  border-radius: 0px 0px 20px 20px;
  background-color: #ffffff;
}
.small-verde {
  color: #66b78e;
  font-size: 0.625rem;
  font-family: RubikBold;
}
.titulo-card-verde {
  color: #026e51;
  font-family: RubikBold;
  font-size: 1.125rem;
}
.li-card-verde {
  color: #026e51;
  font-family: RubikMedium;
  font-size: 0.875rem;
}
.icon-amariilo {
  color: #e3fe33;
}
.p-precio-card-verde {
  font-family: RubikRegular;
  font-size: 1.375rem;
  color: #026e51;
}
.linheight-card {
  line-height: 1.4;
}
.btn-card-oferta {
  background-color: #66b78e !important;
  border-radius: 36px !important;
  color: #ffffff !important;
  font-family: RubikMedium;
  width: 100%;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
}
.btn-card-oferta:hover {
  color: #66b78e;
  background-color: #ffffff;
  border-color: #66b78e;
}
.spna-bagde-verde {
  background-color: #66b78e;
  color: #ffffff;
  font-family: RubikBold;
  font-size: 0.625rem;
  border: 2px solid #ffffff;
  border-radius: 10px;
  position: absolute;
  right: 0.875rem;
  top: -3%;
  z-index: 1;
  text-align: center;
  padding: 2px 22px;
}
.col-padding-top {
  padding-top: 5%;
}
.secc-before-verde::before {
  content: "";
  background-color: #66b78e;
  border-radius: 0px 140px 0px 0px;
  box-shadow: 0px 10px 16px #00000029;
  position: absolute;
  left: 0;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: 96%;
}
.secc-anaranjado {
  background-color: #f0994a;
  box-shadow: 0px 10px 16px #00000029;
}
.secc-top-dos {
  margin-top: 8%;
}
.p-descripcion-blanco-28 {
  color: #ffffff;
  font-size: 1.5625rem;
  font-family: RubikRegular;
  line-height: 1.3;
}
.btn-ver-blanco {
  background-color: #ffffff !important;
  border-radius: 36px !important;
  height: 3.125rem !important;
  color: #414141 !important;
  font-family: RubikRegular;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-in-out;
}
.btn-ver-blanco:hover {
  background-color: #414141 !important;
  color: #ffffff !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.div-verde-slide {
  background-color: #66b78e;
  margin: 0px auto;
  width: 100%;
  box-shadow: 0px 10px 16px #00000029;
  border-radius: 0px 140px 0px 0px;
  padding-top: 10rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.titulo-slider {
  color: #fff;
  font-family: RubikRegular;
  font-size: 1.75rem;
}
.small-slider {
  color: #fff;
}
.p-descripcion-blanco-slider {
  color: #fff;
  font-size: 1.125rem;
  font-family: RubikLight;
}
.avatar-circle-slider {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.div-avatar-slider {
  position: absolute;
  left: 7%;
  top: 3%;
  background-color: #fdc727;
  border: 7px solid #ffffff;
  box-shadow: 0px 15px 15px #00000029;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  padding: 7px;
}
.flechas-color-ancho {
  width: 0px !important;
  color: #414141 !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 30px !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}
.swiper-pagination-bullet-active {
  background: #66b78e !important;
}
.swiper-pagination-bullet {
  background: #a1dec1 !important;
  width: 12px !important;
  height: 12px !important;
}
.img-logos-slider {
  width: 80% !important;
}
.accordion-container {
  width: 100%;
  margin: 0 0 10px;
  clear: both;
}

.accordion-titulo {
  /* position: relative; */

  display: flex;
  padding: 12px 26px;
  font-size: 1.25rem;
  font-weight: 300;
  background: #66b78e;
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
}
.accordion-titulo1 {
  /* position: relative; */
  display: flex;
  padding: 12px 26px;
  font-size: 1.25rem;
  font-weight: 300;
  background: #66b78e;
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  margin: 0;
}
.MuiAccordion-rounded {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.MuiAccordionSummary-root {
  display: flex !important;
  padding: 12px 26px !important;
  font-size: 1.25rem !important;
  font-weight: 300 !important;
  background: #66b78e !important;
  color: #fff !important;
  text-decoration: none !important;
  border-radius: 30px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  background: #fdc727 !important;
  color: #026e51 !important;
  margin: 0 !important;
}
.MuiAccordionSummary-content.Mui-expanded,
.MuiAccordionSummary-content {
  margin: 0 !important;
  align-self: center;
}
.accordion-titulo1.open {
  background: #fdc727;
  color: #026e51;
  margin: 0 !important;
}
.accordion-titulo.open .spanicon::before {
  color: #fff;
}
.accordion-titulo:hover {
  background: #fdc727;
  color: #026e51;
}
.accordion-titulo .spanicon::before {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.accordion-titulo span.toggle-icon::before {
  font-family: FontAwesomeFree;
  font-weight: 900;
  content: "\f107";
}

.accordion-titulo.open span.toggle-icon::before {
  font-family: FontAwesomeFree;
  font-weight: 900;
  content: "\f106";
}

.accordion-titulo span.toggle-icon {
  position: absolute;
  top: 22%;
  right: 23px;
  font-size: 1.375rem;
  font-weight: bold;
}

.accordion-content {
  display: none;
  padding: 10px 26px;
  overflow: auto;
}

.accordion-content p {
  margin: 0;
}

.accordion-content img {
  display: block;
  float: left;
  margin: 0 15px 10px 0;
  width: 50%;
  height: auto;
}
.p-title-acordeon {
  margin: 0 !important;
  font-family: RubikLight;
  font-size: 1.125rem;
}
.p-descripcion-acordeon {
  color: #3c3c3c;
  font-family: RubikLight;
  font-size: 1.125rem;
  margin: 0 !important;
  /* margin-bottom: 0 !important; */
}
.btn-preguntas {
  border: 1px solid #66b78e !important;
  border-radius: 36px !important;
  color: #026e51 !important;
  font-family: RubikRegular;
  padding: 12px 30px !important;
  height: 3.125rem !important;
  transition: all 0.3s ease-in-out !important;
}
.btn-preguntas:hover {
  color: #ffffff !important;
  background-color: #026e51 !important;
}
.btn-empezar {
  border: 1px solid #66b78e;
  border-radius: 36px;
  color: #ffffff;
  font-family: RubikRegular;
  padding: 12px 30px;
  height: 3.125rem;
  background-color: #66b78e;
  transition: all 0.3s ease-in-out;
}
.btn-empezar:hover {
  color: #66b78e;
  background-color: #ffffff;
}
.logo-footer {
  width: 70%;
}
.p-descripcion-footer {
  color: #026e51;
  font-family: RubikLight;
  font-size: 0.875rem;

}
.img-redes-sociles {
  width: 100%;
}
.link-sociales {
  width: 10%;
}
.titulos-medium-footer {
  font-family: RubikMedium !important;
  color: #414141 !important;
}
.li-plomo-footer {
  color: #242424 !important;

}
.li-plomo-footer a {
    color: #242424 !important;
    text-decoration: none;
  }
.div-border-top {
  border-top: 1px solid #707070;
}
.p-widht-footer {
  width: 100%;
}
.titulo-color-blanco {
  color: #ffffff !important;
}
.secc-anaranjado-whipay::before {
  content: "";
  width: 100%;
  height: 55%;
  background-color: #f0994a;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.div-card-blanco {
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;
  padding-top: 2.8125rem;
  padding-bottom: 1.5625rem;
}
.img-card-blanco {
  width: 30%;
}
.div-img-card-blanco {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 4rem;
}
.btn-vender {
  border: 1px solid #66b78e;
  border-radius: 36px;
  color: #026e51;
  font-family: RubikRegular;
  padding: 12px 30px;
  height: 3.125rem;
  background-color: #fdc727;
  transition: all 0.3s ease-in-out;
}
.btn-vender:hover {
  color: #fdc727;
  background-color: #026e51;
}
.swiper-vende {
  width: 100% !important;
  margin-left: 0% !important;
}
.img-circle-radius {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.div-circle-perfil {
  margin: 0px auto;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 1px solid #dedede;
  box-shadow: 0px 15px 15px #00000029;
  padding: 7px;
  background-color: #ffffff;
}
.div-asolute-perfil {
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0;
}
.swiper-verde-before {
  width: 100%;
  margin: 0px auto;
}
.div-padding-left-right-verde {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background-color: #66b78e;
  box-shadow: 0px 10px 16px #00000029;
  border-radius: 0px 0px 0px 0px;
  padding-top: 8.375rem;
  padding-bottom: 2.6875rem;
}
.p-cantidad-caracteres {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 5.125rem;
}
.p-cantidad-caracteres-card {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 5.1875rem;
}
.titulo-caracteres-dos {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.img-asolute-vende {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
  top: 3%;
  opacity: 0.11;
}
.fondo-transparent {
  background-color: transparent !important;
}
.btn-nav-celular {
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: #66b78e !important;
  font-size: 2.1875rem !important;
}
.btn-nav-celular:hover {
  color: #66b78e;
}
.img-nav {
  width: 40%;
}
.subtitulo-light {
  font-family: RubikLight !important;
  font-weight: 100 !important;
}
.img-estadistica {
  height: 500px;
}
.img-absolute-anaranjado {
  position: absolute;
  width: 100%;
  height: 115%;
  top: 0;
  left: 0;
  z-index: -1;
}
.secc-anaranjado-radius {
  padding-top: 0%;
}
.video-heig {
  height: 60vh !important;
}
.p-cabecera {
  font-family: RubikRegular;
  font-size: 1.375rem;
  color: #026e51;
}
.img-celular {
  width: 100%;
}
.div-steps {
    flex-direction: row-reverse;
    align-items: center;
}
.navbar-logo {
    max-width: 200px;
}

@media screen and (min-width: 640px) {
  .div-titulos {
    padding-bottom: 8%;
  }
  .typed-text {
    min-height: 120px;
  }
  .img-celular {
    width:80%;
  }
  .div-titulos-left-right {
    padding-left: 5% !important;
    padding-right: 46% !important;
  }
  .video-heig {
    height: 80vh !important;
  }
  .div-padding-left-right-verde {
    padding-left: 5.3125rem;
    padding-right: 5.3125rem;
    border-radius: 0px 140px 70px 131px;
  }
  .swiper-verde-before {
    width: 85%;
  }
  .img-asolute-vende {
    top: 25%;
  }
  .p-widht-footer {
    width: auto;
  }
  .flechas-color-ancho {
    color: #66b78e !important;
  }
  .btn-ver-blanco {
    width: 25%;
  }
  .secc-before-verde::before {
    height: 93%;
    border-radius: 0px 140px 70px 131px;
  }
  .border-negro-color {
    width: 70% !important;
    margin-left: 30% !important;
  }
  .titulo-plomo {
    font-size: 2.375rem;
  }
  .titulo {
    font-size: 3rem;
  }
  .div-content-cuadrado {
    height: 31.25rem;
  }
  .link-sociales {
    width: 20%;
  }
  .div-verde-slide {
    width: 90%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 9.3125rem;
    padding-right: 3.1875rem;
    border-radius: 0px 140px 70px 131px;
  }
  .div-avatar-slider {
    width: 8rem;
    height: 8rem;
    left: 6%;
    top: 15%;
  }
  .div-radius-amarrilo {
    padding: 3.0625rem 3rem;
    border-radius: 0px 140px 70px 131px;
  }
  .div-content-cuadrado::before {
    right: -3%;
  }
  .div-conten-video {
    height: 120vh;
  }
  .div-conten-videovender {
    height: 160vh;
  }
  .btn-border-blanco {
    width: 65%;
    margin-left: 35%;
  }
  .div-verde {
    padding: 3.0625rem 2rem;
    height: 31.25rem;
    border-radius: 10px;
  }
  .subtitulo-blanco {
    font-size: 2rem;
  }

}
@media screen and (min-width: 768px) {
  .img-celular {
    /* right: 0%;
    top: 30%;
    z-index: 1; */
    width: 84%;
  }
  .typed-text {
    min-height: 100px;
  }
  .div-titulos-left-right {
    padding-left: 5% !important;
    padding-right: 46% !important;
  }
  .img-absolute-anaranjado {
    height:120%
}
.video-mobile {
   width:50%;
   margin: auto;
  }
  .video-heig {
    height: 35vh !important;
  }
  .div-titulos {
    padding-bottom: 20%;
  }
  .img-asolute-vende {
    top: 30%;
    width: 80%;
  }
  .swiper-vende {
    width: 75% !important;
    margin-left: 25% !important;
  }
  .link-sociales {
    width: 15%;
  }
  .div-verde-slide {
    width: 70%;
  }
  .div-radius-amarrilo {
    padding: 3.0625rem 4rem;
  }
  .btn-border-blanco {
    width: 50%;
    margin-left: 50%;
  }
  .subtitulo-blanco {
    font-size: 3rem;
  }
  .div-conten-video {
    height: 90vh;
  }
  .div-conten-videovender {
    height: 60vh;
  }
  .div-avatar-slider {
    width: 10rem;
    height: 10rem;
    left: 7%;
    top: 18%;
  }
  .div-verde {
    width: 100%;
    padding: 3.0625rem 4rem;
  }
  .div-content-cuadrado::before {
    right: -1%;
    height: 30rem;
  }
  .nav-color {
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .img-celular {
    /* right: 0%;
    top: 25%;
    z-index: 1; */
    width: 100%;
  }
  .titulo {
    text-align: start;
  }
  .typed-text {
    text-align: start;
  }
  .div-btn-register {
    justify-content: start;
  }
  .div-titulos-left-right {
    padding-left: 12% !important;
    padding-right: 40% !important;
  }
  .video-heig {
    height: 50vh !important;
  }
  .div-titulos {
    padding-bottom: 10% !important;
  }
  .div-conten-video {
    height: 80vh;
  }
  .div-conten-videovender {
    height: 60vh;
  }
  .img-asolute-vende {
    top: 25%;
    width: 65%;
  }
  .swiper-vende {
    width: 60% !important;
    margin-left: 40% !important;
  }
  .div-avatar-slider {
    width: 13.3125rem;
    height: 13.3125rem;
  }
  .btn-ver-blanco {
    margin-right: 0px;
    margin-left: 0px;
  }
  .img-absolute-left {
    display: block;
  }
  .div-verde {
    width: 90%;
    padding: 3.0625rem 6.5rem;
  }
  .div-content-cuadrado::before {
    right: 8%;
    height: 30.625rem;
  }
  .div-radius-amarrilo {
    padding: 3.0625rem 4rem;
  }
  .secc-before-verde::before {
    bottom: 3%;
    height: 83%;
    width: 60%;
  }
  .col-padding-top {
    padding-top: 40%;
  }
  .secc-padding-top {
    padding-top: 0%;
  }
}
@media screen and (min-width: 1200px) {
  /* .img-celular {
    right: 0%;
    top: 15%;
    z-index: 1;
    width: 57%;
  } */
  video.divdeo-fndo {
    display: inline;
  }
  .div-titulos-left-right {
    padding-left: 12% !important;
    padding-right: 52% !important;
  }
  .secc-img {
    /* background-image: url("../video/Animación 04-1.webm"); */
    background-position: 85% bottom;
    background-repeat: no-repeat;
    background-size: 48%;
  }
  .div-titulos {
    padding-bottom: 10% !important;
  }
  .titulo-padding {
    padding-right: 30%;
  }
  .div-conten-videovender {
    height: 95vh;
  }
  .img-asolute-vende {
    top: 3%;
  }
  .swiper-vende {
    width: 50% !important;
    margin-left: 50% !important;
  }
  .link-sociales {
    width: 10%;
  }
  .div-verde {
    width: 70%;
  }
  .div-content-cuadrado::before {
    right: 28%;
  }
  .div-radius-amarrilo {
    padding: 3.0625rem 6.5rem;
  }
  .secc-before-verde::before {
    bottom: -8%;
  }
  .col-padding-top {
    padding-top: 15%;
  }
  .navbar-logo {
    max-width: 250px;
    }
    .nav-color {
        font-size: 18px;
      }
}
@media screen and (min-width: 1300px) {
  .div-titulos {
    padding-bottom: 3% !important;
    padding-left: 12% !important;
    padding-right: 10% !important;
  }
  /* .img-celular {
    right: 3%;
    top: 15%;
    z-index: 1;
    width: 50%;
  } */
}

a:hover {
    color: #333 !important
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px)
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px)
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px)
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px)
  }
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiStepIcon-root {
  border: 3px solid #808285;
  border-radius: 50px;
  color: transparent;
}

.MuiStepIcon-root {
  color: transparent !important;
}

.MuiStepIcon-text {
  fill: #808285 !important;
}

.MuiStepIcon-active {
  border-color: #53CD94;
  color: transparent !important;
}

.MuiStepIcon-completed {
  border-color: #53CD94;
  color: #53CD94 !important;
  padding: 1px;
}

.MuiStepIcon-completed > .MuiStepIcon-text,
.MuiStepIcon-active > .MuiStepIcon-text {
  fill: #53CD94 !important;
}

div[class*=" Carousel-prev-"] {
  right: 50px !important;
  left: auto !important;
}

.react-multi-carousel-list {
  width: 100% !important;
  min-height: 100px;
  padding-bottom: 30px
}

.dashboard-offer-dot-style > li > button {
  border-color: #C4C4C4;
  background: #C4C4C4;
}

.dashboard-offer-dot-style > li.react-multi-carousel-dot--active > button {
  background: #53CD94 !important;
  border-color: #53CD94;
}

.react-multiple-carousel__arrow--left {
  left: 0% !important;
  top: 125px !important;
}

.react-multiple-carousel__arrow--right {
  right: 0% !important;
  top: 125px !important;
}


.MuiInput-underline.Mui-disabled:before {
  border-bottom: none !important;
}

#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left,
#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  background: #FFF !important;
  min-width: 24px !important;
  min-height: 24px !important;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(224, 224, 224);
  color: rgb(83, 205, 148);
  border-radius: 4px;
  top: 100px !important;
}

#by-seller-slider .react-multiple-carousel__arrow::before {
  color: rgb(83, 205, 148) !important;
  font-size: 14px !important;
}

#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 5px !important;
}

#by-seller-slider .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 5px !important;
}

.kr-embedded {
  width: 450px !important;
  padding: 20px !important;
}
.kr-embedded .kr-payment-button {
  background-color: #53cd94 !important;
  border-radius: 8px;
  width: 400px !important;
  box-shadow: 3px 3px 3px #808285 !important;
  margin-bottom: 20px !important;
}
.kr-pan, .kr-expiry, .kr-security-code, .kr-installment-number, .kr-first-installment-delay  {
  border-radius: 5px !important;
  border: 1px solid #808285 !important;
  width: 400px !important;
}

/* .kr-expiry{
  border-radius: 5px !important;
  border: 1px solid #808285 !important;
  width: 400px !important;
}
.kr-security-code{
  border-radius: 5px !important;
  border: 1px solid #808285 !important;
  width: 400px !important;
} */

.kr-embedded .kr-modal-wrapper .kr-modal .kr-modal-footer button span, .kr-embedded .kr-payment-button span {
  color: white;
  font-weight: 800;
  font-size: 20px;
}
/*
.kr-installment-number{
  display: none !important;
  visibility: hidden !important;
  width: 400px !important;
  border-radius: 5px !important;
}

.kr-first-installment-delay{
  display: none !important;
  visibility: hidden !important;
  width: 400px !important;
  border-radius: 5px !important;
} */
/* .MuiBox-root-3{
  padding:0 !important;
  box-shadow: none !important;

} */
.MuiPaper-elevation1{
  box-shadow: none !important;
}
@media only screen and (max-width: 600px) {
  .kr-embedded {
    width: 100% !important;

  }
  .react-multiple-carousel__arrow {
      min-width: 30px;
      min-height: 30px;
  }
  .react-multiple-carousel__arrow--left {
    left: 0% !important;
    top: 110px !important;
  }

  .react-multiple-carousel__arrow--right {
    right: 0% !important;
    top: 110px !important;
  }
  .kr-pan, .kr-expiry, .kr-security-code, .kr-installment-number, .kr-first-installment-delay{
    /* border-radius: 5px !important;
    border: 1px solid #808285 !important; */
    width: 100% !important;
  }
  .kr-embedded .kr-payment-button {
    /* background-color: #53cd94 !important;
    border-radius: 8px; */
    width: 100% !important;
    /* box-shadow: 3px 3px 3px #808285 !important;
    margin-bottom: 20px !important; */
  }
}

